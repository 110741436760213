<template>
  <div>
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="parameters"
      @updated-fields="updateFields"
    ></fieldsRows>
    <selenium
      v-model="localCommands"
      excludeId
      @template-id-changed="addToVariables"
      :result="result"
      :isValid="isValid"
    ></selenium>
  </div>
</template>
<script>
import selenium from "@/components/legacy/selenium.vue";
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import { updateTestVariables } from "@/js/testHelpers.js";

export default {
  components: { fieldsRows, selenium },
  data() {
    return {};
  },
  props: ["result", "stepStoredValue", "isValid", "stepType", "stepID"],

  computed: {
    localCommands: {
      get() {
        return this.stepStoredValue.commands;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };
        this.$set(localStoredStep, "commands", newVal);
        this.$emit("stored-value-changed", localStoredStep);
      },
    },
    parameters() {
      let parameters = {};
      if (typeof this.stepStoredValue.parameters != "undefined") {
        parameters = this.stepStoredValue.parameters;
      }
      return parameters;
    },
    templateContent() {
      // console.log(this.stepStoredValue);
      let templateContent = {
        fields: {
          screenSize: {
            type: "select",
            associatedOption: this.result.additional.automatedDevices,
            //value: this.parameters.screenSize,
            fieldAttrInput: { class: "required" },
          },
          localClientId: {
            type: "select",
            //value: this.parameters.localClientId,
            associatedOption: this.result.additional.httpClientsArray,
            fieldAttrInput: { class: "required" },
            addingURL:
              "serve.php?f=configuration&f2=outboundConnections&f3=localClients&f4=nocsClients&p=System&c_subType=HTTP&special=selenium",
          },

          debug: {
            type: "checkbox",
            //value: this.parameters.debug,
            trueFalse: true,
          },
          // automation: {
          //   type: "automation",
          //   value: this.stepStoredValue.automation,
          //   seleniumBox: this.result["additional"]["seleniumBox"],

          // },
        },
        additional: this.result.additional,
        dialogRef: "",
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      templateContent.fields = this.languageHelpProvider(
        templateContent.fields,
        "Web_Driver"
      );
      templateContent.disableAllInputs = this.result.disableAllInputs;
      return templateContent;
    },
  },
  methods: {
    updateFields(info) {
      console.log(info);
      if (typeof info.localClientId != "undefined") {
        this.$set(
          this.result.additional,
          "httpClientsArray",
          info.localClientId.associatedOption
        );

        this.$set(
          this.stepStoredValue.parameters,
          "localClientId",
          info.localClientId.value
        );

        this.$emit("stored-value-changed", this.stepStoredValue);
        this.$store.commit("dialogResultChanged", this.result);
      }
    },
    addToVariables(id, object) {
      let testVariables = {};
      if (typeof this.result.valueLine.c_testVariables == "string") {
        testVariables = JSON.parse(this.result.valueLine.c_testVariables);
      }
      updateTestVariables(id, object, testVariables, this, []);
    },

    updateFieldValue(field, value) {
      // console.log("changed",field, value);
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.parameters == "undefined") {
        this.$set(localStoredStep, "parameters", {});
      }
      this.$set(localStoredStep.parameters, field, value);
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>


